import { defineAsyncComponent, hydrateOnVisible } from 'vue'
// import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载

const componentsMap = {
  // 轮播图
  'SideslipImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-sideslip-image" */'./sideslip-image/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 单图 双图 三图 四图
  'StaticImageCardController': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-static-image" */'./static-image/StaticImageFactory.js'),
    hydrate: hydrateOnVisible(),
  }),
  // 一行三品牌
  'BrandRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-brand-recommend" */'./brand-recommend/index'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺品类推荐
  'StoreCategoryDisplayRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-category-display-recommend" */'./store-category-display-recommend/store-category-display-recommend.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 单活动banner
  'StorePageSingleActivityBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-single-activity-banner" */'./activity-banner/single-activity-banner.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品类展示banner
  'CategoryDisplay': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-category-display" */'./category-display-comp/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺4图和2.5图
  'StoreSideslipImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-sideslip-image" */'./sideslip-image-with-abt/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺视频组件
  'StoreVideo': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-video" */'./store-video/StoreVideo.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺热搜词组件
  'StoreHotSearchtermRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-hot-search" */'./store-hot-search/StoreHotSearch.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品类热度推荐
  'StoreCategoryRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-category-recommend" */'./store-category-recommend/StoreCategoryRecommend.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 平台/店铺 横向券
  'StoreHorizontalCoupon': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-coupon-list" */'./coupon/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 平台/店铺 纵向券
  'StoreVerticalCoupon': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-coupon-list" */'./coupon/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌展示banner
  'BrandDisplay': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-store-brand-display" */'./brand-display-comp/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 活动小banner（自定义商卡）
  'ActivityBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-activity-banner" */'./activity-banner/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺闪购组件（自定义商卡）
  'StoreFlashSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-flash-sale" */'./store-flash-sale/StoreFlashSale.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺lookbook组件（自定义商卡）
  'StoreLookbook': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-lookbook" */'./look-book/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品类品牌单品组件（自定义商卡）
  'CategoryBrandItem': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-category-brand-item" */'./category-brand-item/CategoryBrandItem.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 搭配展示组件（自定义商卡）
  'CollocationDisplay': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-collocation-display" */'./collocation-display-comp/CollocationDisplay.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品类商品列表（v3商卡）
  'CategoryItemsDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-category-items-dynamic" */'./category-items-dynamic/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 自选横滑商品（v3商卡）
  'SelectedHorizontalProductsDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-product-items" */'./selected-horizontal-products-dynamic/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺单品（v3商卡）
  'SingleProduct': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-single-product" */'./single-product/SingleProduct.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺dailynew组件（v3商卡）
  'StoreDailynew': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-daily-new" */'./daily-new/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺促销活动组件（v3商卡）
  'ShopActivity': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-shop-activity" */'./shop-activity/ShopActivity.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺纵向列表（v3商卡）
  'StoreVerticalItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-vertical-items" */'./vertical-items/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺横向列表（v3商卡）
  'StoreHorizontalItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-horizontal-items" */'./horizontal-items/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺装修热销榜（v3商卡）
  'StoreRankHotSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-rank-hot-sale" */'./rank-hot-sale/RankHotSale.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺装修上新（v3商卡）
  'StoreNewArrivalList': defineAsyncComponent({
    loader: () => import(/*  webpackChunkName: "bff-ccc-store-new-arrival-list" */'./new-arrival-list/NewArrivalList.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺折扣商品组件（v3商卡）
  'StoreDiscountProductsRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName "bff-ccc-store-discount-products-recommend" */'./store-discount-products-recommend/StoreDiscountProductsRecommend.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 推荐商品组件（v3商卡）
  'RecommendFlow': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-recommend-flow" */'public/src/pages/components/ccc/components/recommend-flow/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺关注引导组件（homeTab插坑，非cccx组件）
  'StoreFollow': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "bff-ccc-store-follow" */'../../../store_pages/storeBanner/FlowFollow.vue'),
    hydrate: hydrateOnVisible(),
  }),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     // propWhole: true,
//     componentFactory: cur[1],
//     customProps: {
//       index: 'number',
//       propData: 'object',
//       propHooks: 'object',
//       content: 'array',
//       context: 'object',
//       sceneData: 'object',
//       cateLinks: 'object',
//       language: 'object',
//       brand: 'string',
//       isLast: 'boolean',
//       isLoad: 'boolean',
//       lazyForceShow: 'boolean'
//     }
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})

export default componentsMap
