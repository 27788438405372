import { defineAsyncComponent, hydrateOnVisible } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
// import StaticImage from '../components/static-image/index.vue'
import CouponPackage from '../components/coupon-package/CouponPackage.vue'
const componentsMap = {
  // 单图
  'StaticImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-static-image" */'../components/static-image/StaticImageFactory'),
    hydrate: hydrateOnVisible(),
  }),
  // 图片组件 卡片控制组件
  'StaticImageCardController': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-static-image" */'../components/static-image-card-controller/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 图文组件
  'TextEdit': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-text-edit" */'../components/text-edit/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 纵向列表
  'VerticalItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-vertical-items" */'../components/verticalItems/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 横向列表
  'HorizontalItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-horizontal-items" */'../components/horizontal-items-entry/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 上图下商品组件
  // 'AbovePictureItems': defineAsyncComponent(() => import(/* webpackChunkName: "ccc-above-picture-items" */'../components/AbovePictureItems')),
  // 左图右商品组件
  'LeftPictureItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-left-picture-items" */'../components/left-picture-items/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // dailynew 组件
  'DailyNewItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-daily-new-items" */'../components/daily-new/index'),
    hydrate: hydrateOnVisible(),
  }),
  // 通用券 / 店铺券   横向 / 纵向
  'HorizontalCoupon': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList'),
    hydrate: hydrateOnVisible(),
  }),
  'VerticalCoupon': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-coupon-list" */'../components/coupon/CouponList'),
    hydrate: hydrateOnVisible(),
  }),
  // 闪购
  'FlashSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
    hydrate: hydrateOnVisible(),
  }),
  // 闪购
  'FlashSaleBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
    hydrate: hydrateOnVisible(),
  }),
  // 左图闪购
  'LeftFlashSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "flash-sale" */'../components/flash-sale/FlashSale'),
    hydrate: hydrateOnVisible(),
  }),
  // 滑动多图组件(轮播图、横滑2.5、横滑4、横滑5)
  'SideslipImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-sideslip-image" */'../components/sideslip-image-with-abt/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 倒计时
  'Countdown': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-countdown" */'../components/countdown/Countdown.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 日历
  'Calendar': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-calendar" */'../components/calendar/Calendar.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 文字、图片锚点
  'HorizontalAnchor': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index'),
    hydrate: hydrateOnVisible(),
  }),
  'HorizontalAnchorImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "horizontal-anchor" */'../components/anchor/index'),
    hydrate: hydrateOnVisible(),
  }),
  // 排行榜入口
  'RankingEntrance': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-ranking-entrance" */'../components/rank/RankingEntranceEntry'),
    hydrate: hydrateOnVisible(),
  }),
  // 排行榜入口 2卡
  'RankingEntranceList2': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-ranking-entrance-list2" */'../components/rank/RankingEntranceList2'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态排行榜组件入口
  'RankingEntranceList2Home': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-ranking-entrance-list2-home" */'../components/rank/RankingEntranceList2Home'),
    hydrate: hydrateOnVisible(),
  }),
  // 排行榜
  'RankingList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-ranking-list" */'../components/rank/RankingList'),
    hydrate: hydrateOnVisible(),
  }),
  // 视频组件
  'Video': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-video" */'../components/video/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 领积分
  'Integral': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-integral" */'../components/integral/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 订阅组件
  'Subscribe': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-subscribe" */'../components/subscribe/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 意见收集组件
  'Survey': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-survey" */'../components/survey/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 推荐商品组件
  'RecommendFlow': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-recommend-flow" */'../components/recommend-flow/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // PK组件
  'PK': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-pk" */'../components/pk/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 付费会员组件
  'Prime': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-club" */'../components/prime/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 凑单免邮组件
  'FreeShippingInfo': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-free-shipping" */ '../components/free-shipping/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌推荐组件
  'BrandRecommend': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-brand-recommend" */'../components/brand-recommend/index'),
    hydrate: hydrateOnVisible(),
  }),
  // 系列搭配
  'LookBook': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-look-book" */'../components/look-book/LookBook'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺卡片列表组件
  'StoreCardList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-card-list" */'../components/store/card-list/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  'StoreHorizontalCardList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-card-list" */'../components/store/card-list/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺单卡片组件
  'StoreSingleCard': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-single-card" */'../components/store/single-card/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 多店铺横滑组件
  'StoreMultiSlide': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-multi-slide" */'../components/store/multi-slide/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 多店铺一店一品组件
  StoreMultiSlideOne: defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-multi-slide-one" */'../components/store/multi-slide-one/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 多店铺圆角卡片组件
  StoreMultiSlideCard: defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-store-multi-slide-card" */'../components/store/multi-slide-card/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新品类热度推荐组件(sbc: suit by category)
  'CategoryRecommendDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-category-recommend-dynamic" */'../components/category-recommend-dynamic/CategoryRecommendDynamicNew'),
    hydrate: hydrateOnVisible(),
  }),
  // SBC 品类热度推荐 占位&产品化 组件
  'CategoryRecommendPlaceholder': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-category-recommend-placeholder" */'../home-bff-components/category-recommend-placeholder/sbc.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 二分之一组件
  'HalfComponentContainer': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-half-comp" */'../components/half'),
    hydrate: hydrateOnVisible(),
  }),
  // 直降组件
  'FallList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-fall-list" */'../components/fall-list/FallList.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 节省组件
  'SaveList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-save-list" */'../components/fall-list/SaveList.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新排行榜落地页
  'HotRankingList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-hot-ranking-list" */'../components/rank-hot/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌馆 - 横滑组件
  'BrandPavilionSideSlip': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-brand-pavilion-side-slip" */'../components/brand-pavilion/SideSlip.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌墙
  'BrandWall': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-static-image" */'../components/brand-wall/BrandWall.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // a~z品牌列表
  'AZBrandList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-az-brand-list" */'../components/az-brand-list/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌新商品流组件
  'BrandItemsList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-brand-items-list" */'../components/brand-items-list/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌馆页头组件
  'BrandHeader': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "brand-header" */'../components/brand-header/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新人活动入口
  'BrandNewusers': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-brand-newusers" */'../components/brand-newusers/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 趋势组件
  'trendItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-trend-entry" */'../components/trend-entry/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 设计师组件
  'SheinXList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-shein-x-list" */'../components/shein-x-list/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 设计师投票组件
  'SheinXVote': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-shein-x-vote" */'../components/shein-x-vote/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 通用搜索
  'UniversalSearch': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-universal-search" */'../components/universal-search/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 转盘抽奖组件
  'DrawSpin': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-draw-spin" */'../components/draw-spin/DrawSpin.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新闻板块组件
  'NewsSection': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-news-section" */'../components/news-section/NewsSection.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 推客券包组件
  'NewUserPackage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-newuser-package" */'../components/newuser-package/NewuserPackage.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新客
  'NewUserHome': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "new-user-home" */'../components/new-user-home/NewUserHome.vue'),
    hydrate: hydrateOnVisible(),
  }),

  // ===== 以下只写动态组件 =====
  // 动态组件 - 横滑多图 - 多TAB、整屏滑动
  'SideslipImageTabDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic-tabs" */'../components/sideslip-image-dynamic/SideSlipTabsCompose.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态组件 - 横滑多图 - 无极横滑
  'SideslipImageMarqueeDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic-marquee" */'../components/sideslip-image-dynamic/SideSlipMarqueeCompose.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态组件 - 闪购 
  'FlashSaleDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "flash-sale-dynamic" */'../components/flash-sale-dynamic/FlashSaleDynamic.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态组件 - 左图闪购 
  'LeftFlashSaleDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "flash-sale-dynamic" */'../components/flash-sale-dynamic/FlashSaleDynamic.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态组件 - 横向商品
  'HorizontalItemsDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-horizontal-items-dynamic" */'../components/horizontal-items-dynamic/HorizontalItemsDynamic.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 动态组件 - 单双券入口
  'CodeDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-code-dynamic" */'../components/coupon-dynamic/SingleCoupon.vue'),
    hydrate: hydrateOnVisible(),
  }),
  'DoubleCodesDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-double-codes-dynamic" */'../components/coupon-dynamic/DoubleCoupon.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 横滑动3.5图
  'SideslipImageDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-sideslip-image-dynamic" */'../components/sideslip-image-dynamic/SideSlipCompose.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 单图 三图
  'DynamicImage': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-dynamic-image" */'../components/static-image-dynamic/StaticImageFactory'),
    hydrate: hydrateOnVisible(),
  }),
  // 沉浸式banner
  'ImmersiveBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-immersive-banner" */'../components/immersive-banner/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 三档券包
  CouponPackage,
  // 不贵组件
  'SuperDeals': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-super-deals" */'../components/super-deals/SuperDeals.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 购物车悬浮球
  'CartIcon': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-cart-icon" */'../components/cart-icon/CartIcon.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 历史最低价组件
  'LowestPrice': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-lowest-price" */'../components/lowest-price/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌品类轮播组件
  'CategorySlide': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-category-slide" */'../components/category-slide/CategorySlide.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺时尚组件
  // 'TrendStoreRecommend': () => import(/* webpackChunkName: "ccc-trend-store-recommend" */'../components/trend-store-recommend/Index.vue'),
  'MultiLine': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-multi-line" */'../components/multi-line/Index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 首页凑单券
  'PurchaseCouponBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-purchase-coupon-banner" */'../home-bff-components/purchase-coupon-banner/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 专题大促不贵组件
  'ActiveSuperDeals': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-super-deals" */'../components/active-super-deals/SuperDeals.vue'),
    hydrate: hydrateOnVisible(),
  }),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     // propWhole: true,
//     componentFactory: cur[1],
//     customProps: {
//       index: 'number',
//       propData: 'object',
//       propHooks: 'object',
//       content: 'array',
//       context: 'object',
//       sceneData: 'object',
//       cateLinks: 'object',
//       language: 'object',
//       brand: 'string',
//       isLast: 'boolean',
//       isLoad: 'boolean',
//       lazyForceShow: 'boolean'
//     }
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare', 'CouponPackage', 'NewUserPackage'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})
componentsMap['ClientOnly'] = ClientOnly

export default componentsMap
