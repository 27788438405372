import { defineAsyncComponent, hydrateOnVisible } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
// import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载
// import StaticImage from '../components/static-image/index.vue'

const componentsMap = {
  // 店铺横向卡片列表
  'StoreHorizontalCardList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-store-card-list" */'../campaigns-components/cccx-store-card-list/cccx-store-card-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺纵向卡片列表
  'StoreCardList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-store-card-list" */'../campaigns-components/cccx-store-card-list/cccx-store-card-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 多店铺横滑组件
  'StoreMultiSlide': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-store-multi-slide" */'../campaigns-components/cccx-store-multi-slide/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 意见收集组件
  'Survey': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-survey" */'../campaigns-components/cccx-survey/cccx-survey.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // look book 组件
  'LookBook': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-look-book" */'../campaigns-components/cccx-look-book/cccx-look-book.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 店铺单卡片组件
  'StoreSingleCard': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-store-single-card" */'../campaigns-components/cccx-store-single-card/cccx-store-single-card.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 设计师组件
  'SheinXList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-shein-x-list" */'../campaigns-components/cccx-shein-x-list/cccx-shein-x-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 设计师投票组件
  'SheinXVote': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-shein-x-vote" */'../campaigns-components/cccx-shein-x-vote/cccx-shein-x-vote.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 图文组件
  'TextEdit': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-text-edit" */'../campaigns-components/cccx-text-edit/cccx-text-edit.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 普通排行榜落地页
  'RankingList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-ranking-list" */'../campaigns-components/rank-page/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌墙
  'BrandWall': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "ccc-static-image" */'../campaigns-components/cccx-brand-wall/cccx-brand-wall.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 新排行榜落地页
  'HotRankingList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-hot-ranking-list" */'../campaigns-components/cccx-rank-hot/cccx-rank-hot.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // a~z品牌列表
  'AZBrandList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-az-brand-list" */'../campaigns-components/cccx-az-list/cccx-az-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 视频组件
  'Video': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-video" */'../campaigns-components/cccx-video/cccx-video.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 直降组件
  'FallList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-fall-list" */'../campaigns-components/cccx-fall-list/cccx-fall-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 节省价组件
  'SaveList': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-save-list" */'../campaigns-components/cccx-save-list/cccx-save-list.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 订阅组件
  'Subscribe': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-subscribe" */'../campaigns-components/cccx-subscribe/cccx-subscribe.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品类品牌单品组件
  'CategoryBrandItem': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-category-brand-item" */'../campaigns-components/cccx-category-brand-item/cccx-category-brand-item.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 品牌横滑组件
  'BrandPavilionSideSlip': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-brand-pavilion-side-slip" */'../campaigns-components/cccx-brand-pavilion-side-slip/cccx-brand-pavilion-side-slip.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 领积分
  'Integral': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-integral" */'../campaigns-components/cccx-integral/cccx-integral.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 闪购
  'FlashSaleBanner': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-flash-sale" */'../campaigns-components/cccx-flash-sale/cccx-flash-sale.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 左图闪购
  'LeftFlashSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-left-flash-sale" */'../campaigns-components/cccx-flash-sale/cccx-left-flash-sale.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 闪购卡片
  'FlashSaleDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-flash-sale-card" */'../campaigns-components/cccx-flash-sale/cccx-flash-sale-card.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 左图闪购卡片
  'LeftFlashSaleDynamic': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-left-flash-sale-card" */'../campaigns-components/cccx-flash-sale/cccx-left-flash-sale-card.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 二分之一容器组件
  'HalfComponentContainer': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-half-comp" */'../campaigns-components/cccx-half/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 上图下商品组件
  'AbovePictureItems': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-above-picture-items" */'../campaigns-components/cccx-above-picture-items/cccx-above-picture-items.vue'),
    hydrate: hydrateOnVisible(),
  }),
  // 专题类目分流器组件
  'SbcSale': defineAsyncComponent({
    loader: () => import(/* webpackChunkName: "cccx-sbc-sale" */'../campaigns-components/sbc-sale/index.vue'),
    hydrate: hydrateOnVisible(),
  }),
}

// const components = Object.entries(componentsMap).reduce((prev, cur) => {
//   prev[`${cur[0]}`] = lazyComponent({
//     // propWhole: true,
//     componentFactory: cur[1],
//     customProps: {
//       index: 'number',
//       propData: 'object',
//       propHooks: 'object',
//       content: 'array',
//       context: 'object',
//       sceneData: 'object',
//       cateLinks: 'object',
//       language: 'object',
//       brand: 'string',
//       isLast: 'boolean',
//       isLoad: 'boolean',
//       lazyForceShow: 'boolean'
//     }
//   })
//   if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
//     prev[`${cur[0]}`] = cur[1]
//   }
//   return prev
// }, {})
componentsMap['ClientOnly'] = ClientOnly

export default componentsMap
